@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  // font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  vertical-align: middle;
}

body {
  line-height: 1;
  overflow-x: hidden;
  font-weight: 400;
  background-color: rgba(30, 144, 255, 0.04);
}

.mantine-19iche8 tbody tr td {
  width: 100%;
  padding: 4px 16px !important;
  border-bottom: 1px solid #eaeaea !important;
  font-size: 0.85em !important;
  vertical-align: middle;
}

.mantine-vfje8a {
  padding: 14px 20px !important;
}

.mantine-5pfa8d {
  vertical-align: baseline;
}

.name-color-checkbox-form {
  display: inline-flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  margin: 8px 0 0 0;

  .textinput {
    width: 75%;
    margin-right: 10px;
  }

  .checkbox {
    margin-bottom: 8px;
  }
}

.dates-form {
  width: 100%;
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 12px;
}

.description-form {
  margin: 8px 0;
}

.flexing-form {
  display: inline-flex;
  align-items: center;
}

.dates {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 14px;
}

.footer {
  position: absolute;
  //   bottom: -56px;
  padding-top: 8px !important;

  font-size: 10px;
  left: 50%;
  translate: -50%;

  p {
    color: #000000 !important;
    font-size: 12px;
    line-height: 14px;
  }
}

@media print {
  @page {
    size: A3 landscape;
    margin: 4mm 8mm 12mm 8mm;

    @top-left {
      content: "Hello";
    }

    @bottom-left {
      content: "Bye";
    }
  }

  footer {
    page-break-after: always;
  }
}
