html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;    
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
}

a:hover {
	text-decoration: none;
	color: #666666;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

button {
	outline: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

*, ::after, ::before {
	margin:0;
	padding: 0;
    box-sizing: border-box;
}

a:focus {
	text-decoration: none;
	outline: none;
}

// a:hover {
// 	color: #fff;
// }

button {
	outline: none;
}

input {
	outline: none;
}

textarea {
	outline: none;
}

section {
	float: left;
	width: 100%;
	position: relative;
}

img {
	float: left;
}

p {
	font-size: 14px;
	line-height: 24px;
	color: #666666;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #e5e5e5 !important;
}
